import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

// TODO: create dev pool
// TODO: switch off of Cognito? Now that most of our backend is on GCP,
// Cognito loses much of its allure. Firebase Authentication exists.
Amplify.configure({
  Auth: {
    identityPoolId: 'us-east-1:4c9991d2-4345-407c-90cd-fc0600618d23',
    region: 'us-east-1',
    userPoolId: 'us-east-1_obcfLn3ET',
    userPoolWebClientId: '7ee1nqs9an6t2tpkh9sd677bp8',
  },
});

if (process.env.NODE_ENV !== 'production') {
  /*
  const axe = require('react-axe');
  axe(React, ReactDOM, 1000);
  */
}

import('./App').then(({ default: App }) => {
  ReactDOM.render(<App />, document.getElementById('root'));
});
